.button {
  width: 300px;
  height: 64px;
}

.buttonBackground {
  fill: #635bff;

  &:hover {
    fill: #7A73FF;
  }
}