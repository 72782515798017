import React from "react";
import DropdownSelector, { DropdownOption } from "./DropdownSelector";

const YearSelector = ({
  start,
  end,
  onSelect,
  selected,
}: {
  start: number;
  end: number;
  onSelect: (DropdownOption) => void;
  selected: number;
}) => {
  const options = React.useMemo(() => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push({ value: i, label: i });
    }
    return options;
  }, [start, end]);

  const selectedOption = options.find((option) => option.value === selected);

  return (
    <DropdownSelector
      options={options}
      defaultOption={options[options.length - 1]}
      onSelect={onSelect}
      selectedOption={selectedOption}
    />
  );

  return <></>;
};

export default YearSelector;
