const TotalCard = ({ title, total }: { title: string; total: number }) => {
  const numberFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const renderedTotal = numberFormatter.format(total);

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500 flex-wrap">
        {title}
      </dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        {renderedTotal}
      </dd>
    </div>
  );
};

export default TotalCard;
