import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import NoInvoicesStateCurrentYear from "./NoInvoicesStateCurrentYear";
import TotalCard from "./TotalCard";
import useStripeInvoices from "../hooks/useStripeInvoices";
import useStripeToken from "../hooks/useStripeToken";
import YearSelector from "./YearSelector";
import NoInvoicesStatePastYear from "./NoInvoicesStatePastYear";

const ALLOWED_STATUSES = ["open", "paid"];

const ToolsPanel = ({
  onYearSelect,
  year,
}: {
  onYearSelect: (number) => void;
  year: number;
}) => (
  <div className="flex flex-col absolute top-4 right-4">
    <YearSelector
      start={2017}
      end={new Date().getFullYear()}
      onSelect={(option) => onYearSelect(option.value)}
      selected={year}
    />
  </div>
);

const TotalsPage = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const [year, setYear] = useState(currentYear);
  const stripeToken = useStripeToken();
  const invoices = useStripeInvoices({ year });
  const navigate = useNavigate();

  useEffect(() => {
    if (stripeToken?.access_token == null) {
      navigate("/");
    }
  }, [stripeToken]);

  if (invoices == null) {
    return <div>Loading...</div>;
  }

  if (invoices.length === 0) {
    return (
      <>
        <ToolsPanel onYearSelect={setYear} year={year} />
        {year === currentYear ? (
          <NoInvoicesStateCurrentYear />
        ) : (
          <NoInvoicesStatePastYear onYearSelect={setYear} />
        )}
      </>
    );
  }

  const invoiceByStatusMap = invoices.reduce(
    (acc, invoice) => {
      const status = invoice.status;
      if (!ALLOWED_STATUSES.includes(status)) {
        return acc;
      }

      if (acc[status] == null) {
        acc[status] = {
          total: 0,
          invoices: [],
        };
      }
      acc[status].invoices.push(invoice);
      acc[status].total += invoice.total;

      acc.total.invoices.push(invoice);
      acc.total.total += invoice.total;
      return acc;
    },
    { total: { total: 0, invoices: [] } }
  );

  const invoiceByCustomerMap = invoices.reduce((acc, invoice) => {
    const customer = invoice.customer.toString();
    if (acc[customer] == null) {
      acc[customer] = {
        total: 0,
        subtotal: 0,
        invoices: [],
      };
    }

    acc[customer].invoices.push(invoice);
    acc[customer].total += invoice.total;
    acc[customer].subtotal += invoice.subtotal;
    return acc;
  }, {});

  const invoiceStatuses = Object.keys(invoiceByStatusMap);
  const invoiceCustomers = Object.keys(invoiceByCustomerMap);

  return (
    <>
      <ToolsPanel onYearSelect={setYear} year={year} />
      <div className="flex flex-col w-10/12">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Invoice Totals ({year})
        </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          {invoiceStatuses.map((status) => {
            const statusStats = invoiceByStatusMap[status];
            return (
              <TotalCard
                key={status}
                title={status.toLocaleUpperCase()}
                total={statusStats.total / 100}
              />
            );
          })}
        </dl>
        <hr className="h-px my-8 bg-gray-800 w-10/12" />{" "}
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Customer Totals ({year})
        </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          {invoiceCustomers.map((customer) => {
            const customerStats = invoiceByCustomerMap[customer];
            return (
              <TotalCard
                key={customer}
                title={customerStats.invoices[0].customer_name.toLocaleUpperCase()}
                total={customerStats.total / 100}
              />
            );
          })}
        </dl>
      </div>
    </>
  );
};

export default TotalsPage;
