import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import TotalsPage from "./components/TotalsPage";
import StripeButton from "./components/StripeButton";
import useStripeLogin from "./hooks/useStripeLogin";

const router = createBrowserRouter([
  {
    path: "/",
    element: <StripeButton />,
  },
  {
    path: "/totals",
    element: <TotalsPage />,
  },
  // {
  //   path: "/customers/:id",
  //   element: <CustomerPage />
  // }
]);

export function App() {
  useStripeLogin();

  return (
    <main className="flex min-h-screen flex-col items-center justify-center bg-gray-100 relative">
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </main>
  );
}
