import { useLocalStorage } from "usehooks-ts";

export const useStripeToken = () => {
  const [stripeToken] = useLocalStorage<any>("stripeToken", {
    cachedAt: 0,
  });

  if (stripeToken?.access_token == null) {
    return null;
  }

  return stripeToken;
};

export default useStripeToken;
