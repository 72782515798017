import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";

const REFRESH_TOKEN_OFFSET = 1000 * 60 * 60 * 24 * 15; // 15 days

export const useStripeLogin = () => {
  const [stripeToken, setStripeToken] = useLocalStorage<any>("stripeToken", {
    cachedAt: 0,
  });

  const getStripeToken = async (code: string) => {
    const token = await (await fetch(`/api/stripe/token?code=${code}`)).json();
    setStripeToken({ ...token, cachedAt: Date.now() });
  };

  // refresh token if it's been more than 15 days
  useEffect(() => {
    if (
      Date.now() - stripeToken.cachedAt > REFRESH_TOKEN_OFFSET &&
      stripeToken.refresh_token != null
    ) {
      getStripeToken(stripeToken.refresh_token);
    }
  }, [stripeToken]);

  const url = new URL(window.location.href);
  const code = url.searchParams.get("code");

  useEffect(() => {
    if (code != null) {
      getStripeToken(code);
      url.searchParams.delete("code");
      window.history.replaceState({}, "", url.toString());
    }
  }, [code]);

  return stripeToken;
};

export default useStripeLogin;
