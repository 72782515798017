import { useEffect, useState } from "react";
import useStripeToken from "./useStripeToken";
import Stripe from "stripe";

export const useStripeInvoices = ({
  year = new Date().getFullYear(),
}: {
  year: number;
}) => {
  const stripeToken = useStripeToken();
  const [invoices, setInvoices] = useState<Stripe.Invoice[] | null>(null);

  useEffect(() => {
    setInvoices(null);
    if (stripeToken != null) {
      fetch(`/api/stripe/invoices?year=${year}`, {
        headers: {
          Authorization: `Bearer ${stripeToken.access_token}`,
        },
      })
        .then((response) => response.json() as Promise<Stripe.Invoice[]>)
        .then((invoices) => {
          setInvoices(invoices);
        });
    }
  }, [stripeToken, year]);

  return invoices;
};

export default useStripeInvoices;
