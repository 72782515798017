import { useMemo } from "react";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";

export default function NoInvoicesStatePastYear({
  onYearSelect,
}: {
  onYearSelect: (year: number) => void;
}) {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <div className="text-center">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M 0 0 M 3 17 V 7 A 2 2 0 0 1 5 5 H 11 L 13 7 H 19 A 2 2 0 0 1 21 9 V 17 A 2 2 0 0 1 19 19 H 5 A 2 2 0 0 1 3 17 Z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No invoices</h3>
      <p className="mt-1 text-sm text-gray-500">
        You should go to a year when you've created invoices to see them here.
      </p>
      <div className="mt-6">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-400 disabled:cursor-not-allowed"
          onClick={() => onYearSelect(currentYear)}
        >
          <CalendarDaysIcon
            className="-ml-0.5 mr-1.5 h-5 w-5"
            aria-hidden="true"
          />
          Go to {currentYear}
        </button>
      </div>
    </div>
  );
}
